.ant-menu-submenu-open {
  background-color: #f0f2f5 !important;
}
.ant-menu {
  border-inline-end: none !important;
}

ion-input {
  --border-width: 1px 1px 1px 1px !important;
  --border-color: #000 !important;
  --border-style: solid !important;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  --background: transparent !important;
  --placeholder-color: #707070 !important;
  --placeholder-opacity: 1 !important;
}
