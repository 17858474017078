ion-content {
  --background: url("https://picsum.photos/2000?grayscale") no-repeat center
    center / cover;
}

ion-input {
  --border-width: 1px 1px 1px 1px !important;
  --border-color: #000 !important;
  --border-style: solid !important;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  --background: transparent !important;
  --placeholder-color: #707070 !important;
  --placeholder-opacity: 1 !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
