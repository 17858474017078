.ant-menu-submenu-open{
    background-color: #f0f2f5 !important;
}
.ant-menu{
    border-inline-end: none !important;
}

.ant-menu-item-selected{
    background-color: #fff !important;
    color: #000 !important;
}